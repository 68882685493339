<template>
<!--  <div class="row m-0 mt-md-5 mb-md-5">-->
  <div class="row m-0 mt-md-5 mb-md-5 m-auto m-w-85">
    <div class="col-md-3 p-0">
      <div class="card-header text-white pt-5 pb-5 h4 animate__animated animate__slideInDown">
        艺&nbsp;术&nbsp;简&nbsp;历
      </div>
      <div class="card-body pl-0 pr-0 animate__animated animate__slideInDown">
        <img class="w-100 m-0" :src="require('../../../static/image/people/' + peoByIdData.peoPhoto + '.jpg')" style="max-height: 300px;object-fit: contain">
      </div>
      <div v-if="peoByIdData.peoName.length <3" class="card-footer bg-success text-white font-weight-bold h5  animate__animated animate__slideInDown">
        <span v-for="(index,item) in peoByIdData.peoName.split('')" :key="item">
          <span class="p-2">{{index}}</span>
        </span>
      </div>
      <div v-if="peoByIdData.peoName.length >=3" class="card-footer bg-success text-white font-weight-bold h5">
          <span class="p-2">{{peoByIdData.peoName}}</span>
      </div>
    </div>
    <div class="col-md-9 animate__animated animate__slideInUp h-100">
      <div class="text-left h3 font-weight-bold">{{  peoByIdData.peoName }}</div>
      <div class="text-left small d-none d-md-block" v-for="(item,index) in peoByIdData.tag" :key="index">
        <span class="badge text-white p-2 float-left mr-3" :class=item.tagType>
          {{ item.tagName}}
        </span>
      </div>
      <div class="text-left mt-md-5 w-100 pb-5 mb-5 ql-container ql-snow" style="min-height: 400px">
        <div class="ql-editor" v-html="peoByIdData.peoInfomation"></div>
      </div>
    </div>
  </div>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
export default {
name: "artistByid",
  data() {
    return {
      peoByIdData: {
        peoName: '',
        peoInfomation: '',
        peoPhoto: ''
      }
    }
  },
  mounted() {
    this.getNewById(this.$route.query.peoId)
  },
  methods: {
    getNewById(peoId) {
      this.$http({
        method: 'get',
        url: '/api/cmsReception/getPeoById/' + peoId,
        data: {}
      }).then((res) => {
        this.peoByIdData = res.data.data
        console.log(this.peoByIdData.peoPhoto)
      })
    }
  }
}
</script>

<style scoped>
.div-center {
  margin: 0 auto;
}
</style>